import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter,Link } from "react-router-dom";
import { getSetting, homeLayoutContent,PageContent, setPathLayout,getContactusModeSetting } from "../actions/Utils";
import '../App.css';
import ContactUsForm from '../components/contactus/ContactUsForm';
// import { image_url, host_name } from '../../config/Api';
import { image_url, host_name } from '../config/Api';
import Banner from '../img/banner3.png';




class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '',
      header: {},
      container: {},
      page_content:{},
      footer: {},
      layout_id: '',
      setting: {},
      contactlayout: null,
      contents_data: {}
    };
  }
  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const { location } = this.props.history;
    var { pathname } = location
    const { path } = this.props.match;

    const slugpage = this.props.match.path.replace('/','');
    this.props.PageContent(slugpage).then((res)=>{

    });

    this.props.getContactusModeSetting({ 'slug': path }).then((data) => {
      console.log("pageSectionContent =", data);
      this.setState({
        contents_data: data,
        slug: path,
        
      })
    });

    this.props.getSetting().then((res) => {
            this.setState({
                setting: res
            })
    });
    

  }

  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  // getSetting = async () => {
  //   let data = await this.props.getSetting();
  // }
  static getDerivedStateFromProps(props) {
    return {
      path: props.utils.path,
      header: props.header,
      container: props.container,
      footer: props.footer,
      layout_id: props.utils.layout_id,
      contactlayout: props.contactuslayout
    };
  }
  getContentData = (menu_id) => {

    const requestData = {
      menu_id: menu_id,
    }
    this.props.sectionandContent(requestData);
  }

  render() {

    
    const {contents_data, setting} = this.state;

    const {page_data}=this.props.page_content.content;


    console.log("contents_data = ", page_data);




    return (
      <Fragment>

        
      
      {page_data && <Fragment>
        <section class="inner-header-bottom pt-70 bg-white">
            <div class="container">
                <div class="contact pb-70">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <h1 class="mb-30"><b>Keep up with us at</b></h1>
                            
                        </div>
                        <div class="col-lg-6">
                          
                          {Object.keys(contents_data).length ? <ContactUsForm data={contents_data} /> : ''}
                            
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="contact-bottom">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-4">
                          <h3>Address</h3>
                          <p>{setting.address}</p>
                      </div>
                      <div class="col-lg-4">
                          <h3>Business Enquiry</h3>
                          <p>{setting.email}</p>
                          <p>{setting.phone }</p>
                      </div>
                      <div class="col-lg-4">
                          <h3>Google Maps</h3>
                          <a target="_blank" href="https://www.google.com/maps/place/Arena+Phone+Bd+Limited/@23.7812155,90.4141964,21z/data=!4m6!3m5!1s0x3755c709f0a03e9f:0x7f8390306da2f7d0!8m2!3d23.7812155!4d90.4143573!16s%2Fg%2F11f5vf5dn5?entry=ttu">Browse Google Maps</a>
                      </div>
                  </div>
              </div>
          </div>
        </section>
        <br />
        <br />

      </Fragment> }

      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  page_content:state.contents.page_content,
  contactuslayout: state.contents.contactus_layout,
  utils: state.utils,
  header: state.header,
  contents: state.contents,
  footer: state.footer,
  layout_id: state.utils.layout_id,
  routemenu: state.utils.routemenu,
  setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
  getSetting: () => dispatch(getSetting()),
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
  PageContent: (requestData) => dispatch(PageContent(requestData)),
  homeLayoutContent: (requestData) => dispatch(homeLayoutContent(requestData)),
  getContactusModeSetting: (requestData) => dispatch(getContactusModeSetting(requestData)),
  
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contactus));