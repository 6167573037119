import React, { Fragment, useEffect, useState } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";
import Htmlcontent from '../../components/Htmlcontent';

  
export const HomeKPI = ({ data }) => {
    useEffect(() => {
        $(document).ready(function () {
            var counted = 0;
            $(window).scroll(function () {
                try {
                    var oTop = $('#counter').offset().top - window.innerHeight;
                    if (counted == 0 && $(window).scrollTop() > oTop) {
                        $('.count').each(function () {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                            $({
                                countNum: $this.text()
                            }).animate({
                                countNum: countTo
                            },

                                {

                                    duration: 2000,
                                    easing: 'swing',
                                    step: function () {
                                        $this.text(Math.floor(this.countNum));
                                    },
                                    complete: function () {
                                        $this.text(this.countNum);
                                        //alert('finished');
                                    }

                                });
                        });
                        counted = 1;
                    }
                } catch {
                    // console.log("Error")
                }
            });
        });
    });

    return (
        
        <Fragment>
            <section class="module section__padding">

                {data && data.map((row, key) => {
                    return (
                        <Fragment>
                            <div class="container">
                                {row.description &&<Fragment>
                                        <Htmlcontent data={row.description} />
                                </Fragment>}
                                


                                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 mb-20">
                                    {row.list && row.list.map((prow, key) => {
                                        return (
                                            <Fragment>
                                                <div class="col">
                                                    <div class="module-single">
                                                        <p><i class="fa fa-solid fa-circle-check"></i> {prow.title ? prow.title : ''}</p>
                                                    </div>
                                               </div>
                                            </Fragment>
                                        )
                                    })}
                                    
                                </div>

                                
                              
                            </div>
                        </Fragment>
                    )

                })}
            </section>  
        </Fragment>
    )
}

