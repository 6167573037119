import $ from 'jquery';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getSetting, homeLayoutContent, setPathLayout } from "../actions/Utils";
// import '../App.css';
import HtmlHelmet from '../components/common/HtmlHelmet';
import {HomeKPI} from '../components/home/HomeKPI';
import {OurExpertise} from '../components/home/OurExpertise';
import {HowWeWork} from '../components/home/HowWeWork';
import {Ecosystem} from '../components/home/Ecosystem';
import {Economy} from '../components/home/Economy';
import {Modules} from '../components/home/Modules';
import WhatWeOffer from '../components/home/WhatWeOffer';
import Htmlcontent from '../components/Htmlcontent';


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            container: {},
            footer: {},
            layout_id: '',
            mobile_view:false
        };
    }

    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { location } = this.props.history;

        if ($(window).width() <= 943) {
            //mobile scene
   
            this.setState({
                
                mobile_view:true
                
            })

        }else{

            this.setState({
                
                mobile_view:false
                
            })

        }

        
        var { pathname } = location
        this.props.homeLayoutContent().then((res) => { })
    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            home_layout: props.home_layout,
        };
    }
    getContentData = (menu_id) => {

        const requestData = {
            menu_id: menu_id,
        }
        this.props.sectionandContent(requestData);
    }

    render() {
        var { path,mobile_view } = this.state;
        
        const { header_loading, footer_loading } = this.props.utils;
        
        
        const { home_layout } = this.props;
        const content = home_layout.content;
        const {page_data}=home_layout.content;
        let str = path.substr(0, 1);
        if (str == '/') {
            path = path.substring(1)
        }

        var home_kpi = [];
        var our_expertise = [];
        var how_we_work = [];
        var ecosystem = [];
        var economy = [];
        var modules = [];
        var bottom_html_content = [];
        var whatweoffer_html_content = [];

        var section_home_kpi = {};
        var section_our_expertise = {};
        var section_how_we_work = {};
        var section_ecosystem = {};
        var section_economy = {};
        var section_modules = {};
        var section_bottom_html_content = {};
        var section_home_whatweoffer = {};

       

        // This technique is used Because page Edit Section Are not Sorted
        if(page_data){

            const {section} = page_data;
            
            if(section){

                section_home_kpi = section.find((row) => {

                    if (row.typename) {
                        if (row.typename === 'Educlerk is Suitable for') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
    
                    else {
                        return false;
                    }
                });
    
                
                if (section_home_kpi) {
                    home_kpi = section_home_kpi.contents; //Home Kpi
    
                }

                section_our_expertise = section.find((row) => {

                    if (row.name) {
                        if (row.name === 'Call to action') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
    
                    else {
                        return false;
                    }
                });
    
                
                if (section_our_expertise) {
                    our_expertise = section_our_expertise.contents[0]['description']; //about top content load
                }



                section_how_we_work = section.find((row) => {

                    if (row.typename) {
                        if (row.typename === 'home how we work') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
    
                    else {
                        return false;
                    }
                });
    
                
                if (section_how_we_work) {
                    how_we_work = section_how_we_work.contents; //Home How we Work
    
                }


                section_ecosystem = section.find((row) => {

                    if (row.name) {
                        if (row.name === 'course' && row.type === 'html') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
    
                    else {
                        return false;
                    }
                });
    
               
                ecosystem = '';
                if (section_ecosystem) {
                    if(section_ecosystem.contents[0])
                    {
                        ecosystem = section_ecosystem.contents[0]['description'];
                    }
    
                }

                section_economy = section.find((row) => {

                    if (row.typename) {
                        if (row.typename === 'economy') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
    
                    else {
                        return false;
                    }
                });
    
                
                if (section_economy) {
                    economy = section_economy.contents; //Home Our Expertise
    
                }

                section_modules = section.find((row) => {

                    if (row.name) {
                        if (row.name === 'Mobile apps Infinitely flexible') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
    
                    else {
                        return false;
                    }
                });
    
                
                if (section_modules) {
                    modules = section_modules.contents; //Home Modules
    
                }


                section_bottom_html_content = section.find((row) => {

                    if (row.type) {
                        if (row.type === 'html') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
    
                    else {
                        return false;
                    }
                });
    
               
    
                if (section_bottom_html_content.name === "Hero Content") {
                    bottom_html_content = section_bottom_html_content.contents[0]['description']; //about top content load
    
                }
                

                section_home_whatweoffer = section.find((row) => {

                    if (row.typename) {
                        if (row.typename === 'what_we_offer') {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
    
                    else {
                        return false;
                    }
                });

                if (section_home_whatweoffer) {
                    whatweoffer_html_content = section_home_whatweoffer.contents; //about top content load
    
                }

 

            } 
        }

        
       
        return (
            <Fragment>
          
                {bottom_html_content &&<Fragment>
                        <Htmlcontent data={bottom_html_content} />
                </Fragment>}
             
                {home_kpi &&<Fragment>
                    <HomeKPI data={home_kpi} />
                </Fragment>}
                
                {ecosystem &&<Fragment>
                        <Htmlcontent data={ecosystem} />
                </Fragment>}

                {modules &&<Fragment>
                    <Modules data={modules} />
                </Fragment>}
                
               
                {how_we_work &&<Fragment>
                    <HowWeWork data={how_we_work} />
                </Fragment>}

                {economy &&<Fragment>
                    
                    <Economy data={economy} mobile_view ={mobile_view} />
                    
                </Fragment>}

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    homelayout: state.homelayout,
    utils: state.utils,
    header: state.header,
    contents: state.contents,
    footer: state.footer,
    layout_id: state.utils.layout_id,
    routemenu: state.utils.routemenu,
    setting: state.utils.setting,
    meta_data: state.utils.meta_data,
    home_layout: state.contents.home_layout,
   

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    homeLayoutContent: (requestData) => dispatch(homeLayoutContent(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));