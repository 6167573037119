
import InnerHTML from 'dangerously-set-inner-html';
import $, { htmlPrefilter } from 'jquery';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFooterSection, getHomePageModal, newsSubscribe } from "../../actions/Utils";
import Htmlcontent from '../Htmlcontent';
import { host_name } from '../../config/Api';
import Cookies from 'js-cookie';
// import '../../Styles/Footer.css';
import LangData from './LangData';
import Logo from '../../img/logo.png';
class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            footerdata: {},
            validator: {
                name: '',
                email: '',
                concern: '',
                message: '',
            },
            message: '',
            message_type: '',
            home_modal: '',
            modal_show: false,
            modalLoad: 0,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = () => {
        $(document).ready(function ($) {
            $('.copyright-year').html(new Date().getFullYear());
        });
        this.props.getFooterSection();

        setTimeout(() => {
            this.props.getHomePageModal().then((data) => {
                this.setState({
                    home_modal: data,
                    modal_show: data ? true : false,
                })
            });
        }, 500);
    }

    static getDerivedStateFromProps(props, state) {

        return {
            footerdata: props.footer,
            lang: props.lang,
            setting: props.setting,
        }
    }

    handleMessageClose = () => {
        this.setState({ message: '', message_type: '' })
    }

    modalLoadcount = () => {
        this.setState({ modalLoad: 1 })
    }

    handleSubmit = () => {



        const userFormData = {

            email: this.email.value,

        }

        const resetValidator = {

            email: '',

        }
        var validation = {};

        if (userFormData.email == '') {
            validation.email = 'The email field is required.';
        }



        if (Object.keys(validation).length) {
            this.setState({ validator: { ...resetValidator, ...validation }, message: '' });

        }
        else {


            this.props.newsSubscribe(userFormData).then((res) => {
                this.email.value = '';

                this.setState({ message: res.data.success, message_type: 'success', validator: { ...resetValidator } })
            })
                .catch(error => {


                    if (typeof error.response.data.errors != "undefined") {
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            validation[key] = value;
                        }
                        this.setState({ validator: validation })
                    }
                    else if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data)) {
                                html += `${value}`;
                            }

                        }


                    } else if (error.request) {

                        // console.log('error.request ',error.request);
                    } else {

                        // console.log('Error', error.message);
                    }
                    if (html) {
                        this.setState({ message: html, message_type: 'danger', validator: { ...resetValidator } })
                    }

                })
        }

    }

    handleCloseModal = () => {

        this.setState({
            modal_show: false,
        })
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        return false;
        // if (event.keyCode  == 13 ) {
        //     event.preventDefault();
        //     return false;
        // }
    }
    render() {

        const { footerdata, modal_show, home_modal, modalLoad } = this.state;

        const { validator } = this.state;
        console.log("footerdata", footerdata);
        const lang = this.props.lang;
        const setting = this.props.setting;
        var placeholder_email_subscribe = lang === 'bn' ? "আপনার ইমেইল কি" : 'What’s your email';
        return (
            <Fragment>
                {footerdata.call_to_action  &&<Fragment>
                        <Htmlcontent data={footerdata.call_to_action} />
                </Fragment>}
                
                <footer>

                    
                    
                    <div class="copyright ">
                    <div class="container" >
                        <div class="row" >

                            <div class="col-md-6">
                                <p class="mb-0">All Rights Reserved | Copyrights © 2024 Arena Excellence</p>
                            </div>
                            <div class="col-md-6">
                                    
                                    <div class="footer-social float-end">
                                        <p>Connect with us</p>
                                        <a href={setting.fb_link} target="_blank"><i class="fab fa-facebook"></i></a>
                                       
                                    </div>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                </footer>

                


            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    footer: state.footer,
    lang: state.utils.lang,
    setting: state.utils.setting,
})

const mapDispatchToProps = (dispatch) => ({
    getFooterSection: (requestData) => { dispatch(getFooterSection(requestData)) },
    newsSubscribe: (requestData) => dispatch(newsSubscribe(requestData)),
    getHomePageModal: (requestData) => dispatch(getHomePageModal(requestData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

